import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectOverviewComponent } from "./pages/project-overview/project-overview.component";
import { ReleaseProjectComponent } from './pages/release-project/release-project.component';
import { EditCorrectionValuesComponent } from './pages/edit-correction-values/edit-correction-values.component';
import { AuthModule } from './pages/auth-pages/auth.module';
const routes: Routes = [
  // Auth routes for guests
  {
    path: 'auth',
    loadChildren: () => AuthModule,
  },

  // Routes for authenticated users
  {
    path:'project-overview', component:ProjectOverviewComponent
  },
  {
    path:'release-project/:id', component:ReleaseProjectComponent
  },
  {
    path:'edit-correction-values/:id', component:EditCorrectionValuesComponent
  },

  { path: '**', redirectTo: '/auth/login' }
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
