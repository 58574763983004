import { Injectable } from '@angular/core';
import { LiebherrProject, Project, ProjectClient, ProjectDto } from '../../api/api.client';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private projectClient : ProjectClient) {
  }

  projects: LiebherrProject[] = [];

  getProjectById (id: string) : LiebherrProject | undefined {
    return this.projects.find(x => x.id == id);
  }

  getProjects(forceUpdate: boolean): Observable<LiebherrProject[]> {

    const subject = new BehaviorSubject<LiebherrProject[]>([]);

    if (forceUpdate || this.projects.length < 0) {
      this.projectClient.getProjects().subscribe(apiItems => {
        subject.next(apiItems);
        this.projects = apiItems;
      });
    } else {
      subject.next(this.projects);
    }

    return subject;
  }

  getProjectsById(id : string): Observable<LiebherrProject[]> {
    return this.projectClient.getProjectsByProjectId(id);
  }

  deleteProject (id: string) :Observable<boolean> {
    return this.projectClient.delete(id);
  }

  updateCorrectionValues (project: LiebherrProject) :Observable<boolean> {

    console.log("updateCorrectionValues")

    var projectDto: ProjectDto = {
      id: project.id,
      projectId: project.projectId,
      correctionValues: project.correctionValues
    }

    return this.projectClient.updateCorrectionValues(projectDto);
  }

  compareProjectVersions (project1: LiebherrProject, project2: LiebherrProject) : number {

    var versionProject1 : String[] = project1.version!.split('.');
    var versionProject2 : String[] = project2.version!.split('.');

    if (versionProject1.length != versionProject2.length ) {
      return 0;
    }

    for (let index = 0; index < versionProject1.length; index++) {

      var version1 = Number(versionProject1[index]);
      var version2 = Number(versionProject2[index]);

      if (version1 < version2) {
        return -1;
      } else if (version1 > version2) {
        return 1;
      }
    }
    return 0;
  }

}

