<div *ngIf="(loggedIn$ | async)">
  <nav class="navbar navbar-expand-lg navbar-light bg-body-light" >
    <div class="container-fluid">
      <a class="navbar-brand" href="#">unipas</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" aria-current="page" routerLink="/project-overview">Project Overview</a>
          </li>
          <li>
            <a class="nav-link" (click)="onLogout()">{{ 'navbar.logout' | translate }}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="container pt-5">
    <router-outlet></router-outlet>
  </div>
</div>
<mat-sidenav-container fullscreen *ngIf="!(loggedIn$ | async)">
  <mat-sidenav #snav mode="side" [opened]="(loggedIn$ | async) && screenWidth > sidenavBreakpoint">

    <mat-nav-list *ngIf="role$ | async as role">
      <mat-list-item class="loggedIn">
        <img src="assets/icon.svg">
        <div><small>{{ 'common.loggedIn' | translate }}</small></div>
        <div *ngIf="name$ | async as name"><b>{{name}}</b> ({{role}})</div>
      </mat-list-item>

      <hr>
      <br>
      <br>

      <ng-container *ngFor="let link of links">
        <mat-list-item routerLink="{{link.link}}" routerLinkActive="active">
          <span>
            {{ link.label | translate }}
          </span>
        </mat-list-item>
      </ng-container>

      <mat-list-item class="logout" (click)="onLogout()">
        <span><mat-icon>logout</mat-icon> {{ 'navbar.logout' | translate }}</span>
      </mat-list-item>
    </mat-nav-list>

  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>
