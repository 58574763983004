import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsStorageService } from './settings-storage.service';
import { AuthStorageService } from './auth-storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public name$ = new BehaviorSubject(this.authStorageService.getName());
  public role$ = new BehaviorSubject(this.authStorageService.getRole());

  constructor(
    private translateService: TranslateService,
    private settingsStorageService: SettingsStorageService,
    private authStorageService: AuthStorageService
  ) {}

  setLanguage(language: string) {
    this.settingsStorageService.setLanguage(language);
    this.translateService.use(language);
  }

  setRole(role: string) {
    this.role$.next(role);
    this.authStorageService.setRole(role);
  }

  setName(name: string) {
    this.name$.next(name);
    this.authStorageService.setName(name);
  }

}
