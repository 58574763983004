import { Component, HostListener, ViewChild } from '@angular/core';
import { AuthService } from '../lib/service/auth.service';
import { UserService } from '../lib/service/user.service';
import { MatSidenav } from '@angular/material/sidenav';
import { map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Liebherr.unipas.Configuration.WebApp';

  loggedIn = false;

  loggedIn$ = this.authService.loggedIn$.pipe(map((loggedIn: boolean) => {
    this.loggedIn = loggedIn;
    return loggedIn;
  }));
  name$ = this.userService.name$;
  role$ = this.userService.role$;

  links = [
    { label: 'navbar.projectOverview', link: '/project-overview' },
  ];

  readonly sidenavBreakpoint = 1000;
  screenWidth = 0;

  @ViewChild('snav') sidenav: MatSidenav | undefined;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.loggedIn) {
      this.screenWidth = event.target.innerWidth;
      if (event.target.innerWidth < this.sidenavBreakpoint) {
        this.sidenav?.close();
      } else {
        this.sidenav?.open();
      }
    }
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
  ) {}

  onLogout(): void {
    this.authService.logout();
  }
}
