import { Component, Input, OnInit } from '@angular/core';
import { LiebherrCorrectionValue, LiebherrProject } from '../../api/api.client';
import { ProjectService } from '../../service/project-service/project.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-correction-values',
  templateUrl: './correction-values.component.html',
  styleUrls: ['./correction-values.component.css']
})
export class CorrectionValuesComponent implements OnInit {

  @Input() project!: LiebherrProject;

  correctionValues : LiebherrCorrectionValue[] = [];
  correctionValueNames : string[] = [];

  newCorrectionValueName: string = ""
  newCorrectionDeviceType: string = ""
  newCorrectionArticleNumber: string = ""

  ngOnInit(): void {

      this.correctionValues = this.project!.correctionValues!;
      this.correctionValueNames = this.getDistinctValues(this.correctionValues.map(x => x.name!));
  }

  addNewCorrectionValue() : void {

    var newCorrectionValue : LiebherrCorrectionValue =
    {
      name: this.newCorrectionValueName,
      deviceType: this.newCorrectionDeviceType,
      articleNumber:  this.newCorrectionArticleNumber,
    };

    this.correctionValues.push(newCorrectionValue);

    this.newCorrectionValueName = "";
    this.newCorrectionDeviceType = "";
    this.newCorrectionArticleNumber = "";
  }

  private getDistinctValues (source : string[]) : string[] {

    var destination: string[] = [];

    source.forEach(element => {
      if (destination.indexOf(element) < 0) {
        destination.push(element);
      }
    });

    destination = destination.sort();

    return destination;
  }
}


