import { Component, OnInit } from '@angular/core';
import { LiebherrProject } from 'src/app/lib/api/api.client';
import { ProjectService } from 'src/app/lib/service/project-service/project.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-correction-values',
  templateUrl: './edit-correction-values.component.html',
  styleUrls: ['./edit-correction-values.component.css']
})
export class EditCorrectionValuesComponent implements OnInit {

  project?: LiebherrProject = undefined;

  constructor (private activatedRoute : ActivatedRoute,
    private projectService : ProjectService) {
  }

  ngOnInit(): void {

    if (this.activatedRoute.snapshot.paramMap.keys.find(x => x ==  'id') != undefined) {
      var id = this.activatedRoute.snapshot.paramMap.get('id')!;
      this.project = this.projectService.getProjectById(id)!;
    }
  }

  submit () {

    console.log("submit");

    this.projectService.updateCorrectionValues(this.project!);

  }

}
