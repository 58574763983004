import { Injectable } from '@angular/core';
import { LiebherrProject, Project, ProjectArticleNumberModificationMapping, ReleaseClient, ReleaseProjectDto } from '../../api/api.client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReleaseService {

  constructor(private client: ReleaseClient) { }

  getProjectArticleNumberModificationMapping (projectId: string, version: string) : Observable<ProjectArticleNumberModificationMapping> {
    return this.client.getProjectCircuitDiagramMapping(projectId, version);
  }

  release (circuitDiagramArticleNumber:string, circuitDiagramModificationIndex: string, project: LiebherrProject) : Observable<Boolean> {


    let releaseProjectDto: ReleaseProjectDto =  {
      circuitDiagram: circuitDiagramArticleNumber,
      modificationIndex: circuitDiagramModificationIndex,
      projectId: project.id,
      correctionValues: project.correctionValues
    }




    return this.client.release(releaseProjectDto);
  }

  unrelease (project: LiebherrProject) {
    this.client.unrelease(project.id);
  }
}
