<h3>Project Overview</h3>
<hr/>

<div class="list-group" *ngFor="let project of projects">

  <div class="list-group-item list-group-item-action mb-2" aria-current="true">
    <div class="d-flex w-100 justify-content-between">
      <div class="mb-1 h5 text-decoration-none">{{project.name}} {{project.version}}</div>
      <button type="button" class="btn btn-success" [disabled]="project.releaseDate" routerLink="/release-project/{{project.id}}">Release</button>
      <button type="button" class="btn btn-primary" routerLink="/edit-correction-values/{{project.id}}">Edit Correction Values</button>
      <button type="button" class="btn btn-warning" [disabled]="!project.releaseDate">Unrelease</button>
      <button class="btn btn-sm btn-danger" [disabled]="project.releaseDate" (click)="delete(project)">x</button>
    </div>
  </div>
</div>
