
<div class="container">
  <div class="header">
    <div class="h3">Edit Correction Values</div>
    <div class="mb-1 h5 text-decoration-none">{{project?.name}} {{project?.version}}</div>
  </div>

  <div class="content">
    <app-correction-values [project]="project!"></app-correction-values>
  </div>

  <div class="footer">
      <button class="btn btn-primary action-button" (click)="submit()">Take Changes</button>
      <button class="btn btn-primary action-button" routerLink="/project-overview">Back to Project Overview</button>
  </div>
</div>
