import { Component, OnInit } from '@angular/core';
import { LiebherrProject } from "../../lib/api/api.client";
import { ProjectService } from '../../lib/service/project-service/project.service';
import { ReleaseService } from 'src/app/lib/service/release-service/release.service';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.css']
})
export class ProjectOverviewComponent  implements OnInit {

  constructor(private projectService : ProjectService, private releaseService: ReleaseService) {
  }

  projects : LiebherrProject [] = [];

  ngOnInit(): void {

    this.projectService.getProjects(true).subscribe(x => {
      this.projects = x;
    });

    this.projects.forEach(element => {
      console.log(element);
    });

  }

  reloadData(update : boolean): void {
    this.projectService.getProjects(update).subscribe(x => {
      this.projects = x;
    });
  }

  delete (project: LiebherrProject) : void {
    var deleted = this.projectService.deleteProject(project.id!);
  }

  unrelease (project: LiebherrProject) {
    this.releaseService.getProjectArticleNumberModificationMapping
  }

}
