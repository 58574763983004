<h3>Connect an unipas project to LIKV</h3>
<div>{{project?.name}} {{project?.version}}</div>

<hr>
<div *ngIf="cannotUpgrade">Cannot update project because there is already a project checked in which is newer</div>

<div *ngIf="canUpgrade">
    <label>Upgrade:   </label>
    <input title="checkbox" type="checkbox" [(ngModel)]="upgradeEnabled">
</div>

<div>
    <h3>Circuit Diagram</h3>

    <div class="row mb-2">
      <div class="col-4">
          <label>Article Number</label>
      </div>
      <div class="col-4">
        <input title="article" type="text" [(ngModel)]="circuitDiagramArticleNumber">
      </div>
  </div>
  <div class="row mb-2">
      <div class="col-4">
        <label>Modification Index</label>
      </div>
      <div class="col-4">
        <input title="article" type="text" [(ngModel)]="circuitDiagramModificationIndex">
      </div>
  </div>

</div>

<div>
  <button class="btn btn-primary" (click)="release()" [disabled]="releaseDisabled || !upgradeEnabled || !circuitDiagramArticleNumber || !circuitDiagramModificationIndex" >Release Project</button>
</div>


<ng-container *ngIf="project">
    <app-correction-values [project]="project"></app-correction-values>
</ng-container>

