import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../lib/service/project-service/project.service';
import { ActivatedRoute } from '@angular/router';
import { LiebherrCorrectionValue, LiebherrProject, Project, ProjectArticleNumberModificationMapping } from '../../lib/api/api.client';
import { ReleaseService } from '../../lib/service/release-service/release.service';
// import {ProjectService } from "../lib/service/project-service";

@Component({
  selector: 'app-release-project',
  templateUrl: './release-project.component.html',
  styleUrls: ['./release-project.component.css']
})

export class ReleaseProjectComponent implements OnInit {

  constructor (private activatedRoute : ActivatedRoute,
    private projectService : ProjectService,
    private releaseService: ReleaseService) {
  }

  project?: LiebherrProject = undefined;

  releaseDisabled: boolean = true;

  upgradeEnabled: boolean = false;
  canUpgrade : boolean = false;
  cannotUpgrade : boolean = false;

  circuitDiagramArticleNumber :string | undefined = "";
  circuitDiagramModificationIndex :string | undefined = "";

  ngOnInit(): void {

    this.upgradeEnabled = false;
    this.canUpgrade = false;
    this.cannotUpgrade = false;

    if (this.activatedRoute.snapshot.paramMap.keys.find(x => x ==  'id') != undefined) {

      var id = this.activatedRoute.snapshot.paramMap.get('id')!;

      this.project = this.projectService.getProjectById(id)!;

      if (this.project != null && this.project != undefined) {

        this.projectService.getProjectsById(this.project.projectId!).subscribe(x => this.initReleatedProjects(x));
        this.releaseService.getProjectArticleNumberModificationMapping(this.project.id!, this.project.version!).subscribe(x => this.initMapping(x))
      }
    }
  }

  release() : void {

    console.log("release")

    /*
    this.project!.correctionValues!.forEach(element => {
      console.log(element.name + " " + element.deviceType)
    });

    this.releaseService.release(this.circuitDiagramArticleNumber!, this.circuitDiagramModificationIndex!, this.project!).subscribe(x => {
      console.log("release finisted")
    });
     */

    this.releaseService.release(this.circuitDiagramArticleNumber!, this.circuitDiagramModificationIndex!, this.project!).subscribe(x => {
      console.log("release finisted")
    });


  }

  private initReleatedProjects (relatedProjects : LiebherrProject[]) {

    if (this.project == null || this.project == undefined) {
      return;
    }

    var currentProjectIndex = relatedProjects.findIndex(x => x.name == this.project!.name && x.version == this.project!.version);
    relatedProjects.splice(currentProjectIndex, 1);

    var newestProject = this.getNewestProject(relatedProjects);

    if (newestProject != undefined) {

        var compareResult = this.projectService.compareProjectVersions(this.project!, newestProject);

        this.canUpgrade = compareResult > 0;
        this.cannotUpgrade = compareResult <= 0;

    } else {
      this.releaseDisabled = false;
      this.upgradeEnabled = true;
    }
  }

  private initMapping (mapping: ProjectArticleNumberModificationMapping) {
    if (mapping != undefined) {
      this.circuitDiagramArticleNumber = mapping.articleNumber;
      this.circuitDiagramModificationIndex = mapping.modificationIndex;
    }
  }

  private getNewestProject (projects: LiebherrProject[]) : LiebherrProject | undefined {

    if (projects.length == 0) {
     return undefined;
    }

    return projects.sort((a,b) => this.projectService.compareProjectVersions(a, b))[0];
  }
}
