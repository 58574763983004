import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsStorageService implements OnDestroy {
  public readonly language$: BehaviorSubject<string> = new BehaviorSubject(this.getLanguage());

  ngOnDestroy(): void {
      this.language$.complete();
  }

  setLanguage(lang: string): void {
    localStorage.setItem('lang', JSON.stringify(lang));
    this.language$.next(lang);
  }

  getLanguage(): string {
    const lang = JSON.parse(localStorage.getItem('lang') as string);

    if (lang) {
      return lang;
    }
    return 'de';
  }
}
