
<div class="container"  *ngIf="correctionValues.length > 0">

  <div class="dataGrid">

    <dx-data-grid
      [columnAutoWidth]="true"
      [dataSource]="correctionValues"
      [height]="'70%'"
      [scrolling]="{ mode: 'virtual' }"
      [showBorders]="true"
      [width]="'100%'">
      <dxo-paging [enabled]="true"> </dxo-paging>

      <dxo-search-panel
        [visible]="true"
        [highlightCaseSensitive]="false"  >
      </dxo-search-panel>

      <dxi-column allowEditing="false"
                  dataField="name"
                  [groupIndex]="0"
                  groupCellTemplate="groupCellTemplate"
      >
      </dxi-column>
      <dxi-column dataField="deviceType"></dxi-column>
      <dxi-column dataField="articleNumber"></dxi-column>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-editing
        mode="cell"
        [allowUpdating]="true"
        [allowAdding]="false"
        [allowDeleting]="true"
        [useIcons]="true"
      >
      </dxo-editing>

      <div *dxTemplate="let data of 'groupCellTemplate'">
        <div>{{data.key}} Items: {{data.value}} Items: {{data.values.description}}</div>
      </div>

    </dx-data-grid>
  </div>

  <div class="actions">

    <div class="row mb-2">

      <div class="col-4">
        <label>Name</label>
      </div>

      <div class="col">
        <select title="addCorrectionValueName" [(ngModel)]="newCorrectionValueName">
          <option *ngFor="let correctionValue of correctionValueNames" >{{correctionValue}}</option>
        </select>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-4">
        <label>Device Type</label>
      </div>
      <div class="col-4">
        <input title="newCorrectionDeviceType" type="text" [(ngModel)]="newCorrectionDeviceType">
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-4">
        <label>Article Number</label>
      </div>
      <div class="col-4">
        <input title="newCorrectionArticleNumber" type="text" [(ngModel)]="newCorrectionArticleNumber">
      </div>
    </div>

    <div>
      <button class="btn btn-secondary action-button" (click)="addNewCorrectionValue()" [disabled]="!newCorrectionDeviceType || !newCorrectionDeviceType || !newCorrectionArticleNumber">Add Correction Value</button>
    </div>

  </div>

</div>
