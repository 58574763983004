import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './pages/app.component';
import { ProjectOverviewComponent } from './pages/project-overview/project-overview.component';
import { FormsModule } from '@angular/forms';
import { DxBulletModule, DxDataGridModule, DxTemplateModule } from 'devextreme-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';

import { API_BASE_URL, AuthClient, ProjectClient, ReleaseClient} from "./lib/api/api.client";
import { ReleaseProjectComponent } from './pages/release-project/release-project.component';
import { EditCorrectionValuesComponent } from './pages/edit-correction-values/edit-correction-values.component';
import { CorrectionValuesComponent } from './lib/components/correction-values/correction-values.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SettingsStorageService } from './lib/service/settings-storage.service';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { BlobErrorHttpInterceptor } from './lib/interceptor/blob.interceptor';
import { ErrorInterceptor } from './lib/interceptor/error.interceptor';
import { JwtInterceptor } from './lib/interceptor/jwt.interceptor';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const translateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
};

export function appInitializerFactory(translate: TranslateService, settingStorageService: SettingsStorageService) {
  return () => {
    const language = settingStorageService.getLanguage();
    translate.setDefaultLang(language);
    return translate.use(language);
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ProjectOverviewComponent,
    ReleaseProjectComponent,
    EditCorrectionValuesComponent,
    CorrectionValuesComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    TranslateModule.forRoot(translateModuleConfig),
    MatSidenavModule,
    MatListModule,
    MatIconModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, SettingsStorageService],
      multi: true
    },
    AuthClient,
    ProjectClient,
    ReleaseClient,
    {
      provide: API_BASE_URL,
      useValue: environment.apiRoot,
    },
    MatSnackBar,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
